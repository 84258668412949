import store from "@state/store"
import { DataSources } from "@mixins/data-sources"

// Solution Identifiers
const salesforceSolutionId = process.env.VUE_APP_TRAY_SALESFORCE_SOLUTION_ID
const msd365SolutionId =
  process.env.VUE_APP_TRAY_MICROSOFT_DYNAMICS_365_SOLUTION_ID
const affinitySolutionId = process.env.VUE_APP_TRAY_AFFINITY_SOLUTION_ID
const hubspotSolutionId = process.env.VUE_APP_TRAY_HUBSPOT_SOLUTION_ID

const TrayHelpers = {
  // CONSTANTS
  STANDARD_SOURCES: [
    DataSources.SALESFORCE,
    DataSources.MICROSOFT_DYNAMICS_365,
    DataSources.HUBSPOT,
  ],
  PROVIDER_PREFIXES: ["slf", "msd365", "afi", "hs"],
  STANDARD_PROVIDER_PREFIXES: ["slf", "msd365", "hs"],
  // Solution Ids
  SALESFORCE: salesforceSolutionId,
  [DataSources.SALESFORCE]: salesforceSolutionId,
  MICROSOFT_DYNAMICS_365: msd365SolutionId,
  [DataSources.MICROSOFT_DYNAMICS_365]: msd365SolutionId,
  AFFINITY: affinitySolutionId,
  [DataSources.AFFINITY]: affinitySolutionId,
  HUBSPOT: hubspotSolutionId,
  [DataSources.HUBSPOT]: hubspotSolutionId,

  // External Ids
  [salesforceSolutionId]: "external_salesforce_authentication",
  [msd365SolutionId]: "external_microsoft_dynamics_365_authentication",
  [affinitySolutionId]: "external_affinity_authentication",
  [hubspotSolutionId]: "external_hubspot_authentication",

  workflowClass: {
    [DataSources.SALESFORCE]: "slf",
    [DataSources.MICROSOFT_DYNAMICS_365]: "msd365",
    [DataSources.AFFINITY]: "afi",
    [DataSources.HUBSPOT]: "hs",
  },

  affinityFieldTypesMapping: {
    person: 0, // Unsupported
    organization: 1, // Unsupported
    dropdown: 2,
    number: 3,
    date: 4,
    location: 5,
    text: 6,
    ranked_dropdown: 7,
    opportunity: 8, // Unsupported
  },
  affinityFieldTypesArray: [
    "person",
    "organization",
    "dropdown",
    "number",
    "date",
    "location",
    "text",
    "ranked_dropdown",
    "opportunity",
  ],

  // HELPER METHODS
  openAuthOnlyWindow(solutionInstance, authOnlyUrl) {
    openTrayAuthOnlyWindow(solutionInstance, authOnlyUrl)
  },
  isSoloCheckbox(sourceUseCase) {
    let useCases = ["boolean"]
    return useCases.includes(sourceUseCase)
  },
  isTrayProvider(word) {
    return this.PROVIDER_PREFIXES.includes(word)
  },
  isStandardTrayProvider(word) {
    return this.STANDARD_PROVIDER_PREFIXES.includes(word)
  },

  // Test Helper Methods
  testOpenAuthOnlyWindow(authOnlyUrl) {
    testOpenTrayAuthOnlyWindow(authOnlyUrl)
  },
}

export { TrayHelpers }

// Tray Auth Flow
export const openTrayAuthOnlyWindow = (solutionInstance, configURL) => {
  // Must open window from user interaction code otherwise it is likely
  // to be blocked by a popup blocker:
  const configWindow = window.open(
    configURL,
    "_blank",
    "width=500,height=500,scrollbars=no"
  )

  // Listen to popup messages
  let authFinished = false
  let authId = null
  const onmessage = (e) => {
    if (e.data.type === "tray.authpopup.error") {
      // Handle popup error message
      alert(`Error: ${e.data.err}`)
    }
    if (!configWindow) {
      return console.error(
        "Tray Helpers - configWindow not defined",
        e.data.type
      )
    }
    if (e.data.type === "tray.authpopup.cancel") {
      configWindow.close()
    }
    if (e.data.type === "tray.authpopup.finish") {
      // Handle popup finish message
      authId = _.get(e, "data.authId")
      authFinished = !_.isEmpty(authId)
      configWindow.close()
    }
    if (e.data.type === "tray.authpopup.validate") {
      // Return validation in progress
      configWindow.postMessage(
        {
          type: "tray.authpopup.client.validation",
          data: {
            inProgress: true,
          },
        },
        "*"
      )

      setTimeout(() => {
        // Add errors to all inputs
        const errors = e.data.data.visibleSlots.reduce((errors, externalId) => {
          console.error(errors)
          // Uncomment next line to set an error message
          // errors[externalId] = 'Custom error message';
          return errors
        }, {})

        // Return validation
        configWindow.postMessage(
          {
            type: "tray.authpopup.client.validation",
            data: {
              inProgress: false,
              errors: errors,
            },
          },
          "*"
        )
      }, 2000)
    }
  }
  window.addEventListener("message", onmessage)

  // Check if popup window has been closed before finishing the configuration.
  // We use a polling function due to the fact that some browsers may not
  // display prompts created in the beforeunload event handler.
  const CHECK_TIMEOUT = 1000
  const checkWindow = (resp) => {
    if (!configWindow || configWindow.closed) {
      // Handle popup closing
      if (!authFinished) {
        alert("Authentication not finished")
      } else {
        // User has Authenticated! Find external id
        let externalId = TrayHelpers[solutionInstance.tray_solution_id]
        if (_.isEmpty(externalId)) {
          return console.error("externalId not set")
        }

        let authValues = [{ externalId: externalId, authId: authId }]
        store.dispatch("solutionInstances/setAuthValues", {
          model: "SolutionInstance",
          id: solutionInstance.id,
          authValues: authValues,
        })
      }
      window.removeEventListener("message", onmessage)
    } else {
      setTimeout(checkWindow, CHECK_TIMEOUT)
    }
  }

  checkWindow()

  return configWindow
}

// Test Tray Auth Flow
// -----------------------------------------------------------------------------------------------
// This test function is only used on test-tray.vue and should match the above function in logic
// -----------------------------------------------------------------------------------------------
export const testOpenTrayAuthOnlyWindow = (configURL) => {
  // Must open window from user interaction code otherwise it is likely
  // to be blocked by a popup blocker:
  const configWindow = window.open(
    configURL,
    "_blank",
    "width=500,height=500,scrollbars=no"
  )

  // Listen to popup messages
  let authFinished = false
  let authId = null
  const onmessage = (e) => {
    if (e.data.type === "tray.authpopup.error") {
      // Handle popup error message
      alert(`Error: ${e.data.err}`)
    }
    if (!configWindow) {
      return console.error(
        "Tray Helpers - configWindow not defined",
        e.data.type
      )
    }
    if (e.data.type === "tray.authpopup.cancel") {
      configWindow.close()
    }
    if (e.data.type === "tray.authpopup.finish") {
      // Handle popup finish message
      authId = _.get(e, "data.authId")
      authFinished = !_.isEmpty(authId)
      configWindow.close()
    }
    if (e.data.type === "tray.authpopup.validate") {
      // Return validation in progress
      configWindow.postMessage(
        {
          type: "tray.authpopup.client.validation",
          data: {
            inProgress: true,
          },
        },
        "*"
      )

      setTimeout(() => {
        // Add errors to all inputs
        const errors = e.data.data.visibleSlots.reduce((errors, externalId) => {
          console.error(errors)
          // Uncomment next line to set an error message
          // errors[externalId] = 'Custom error message';
          return errors
        }, {})

        // Return validation
        configWindow.postMessage(
          {
            type: "tray.authpopup.client.validation",
            data: {
              inProgress: false,
              errors: errors,
            },
          },
          "*"
        )
      }, 2000)
    }
  }
  window.addEventListener("message", onmessage)

  // Check if popup window has been closed before finishing the configuration.
  // We use a polling function due to the fact that some browsers may not
  // display prompts created in the beforeunload event handler.
  const CHECK_TIMEOUT = 1000
  const checkWindow = (resp) => {
    if (!configWindow || configWindow.closed) {
      // Handle popup closing
      if (!authFinished) {
        alert("Authentication not finished")
      } else {
        store.dispatch("trayTest/setAuthId", authId)
      }
      window.removeEventListener("message", onmessage)
    } else {
      setTimeout(checkWindow, CHECK_TIMEOUT)
    }
  }

  checkWindow()

  return configWindow
}
